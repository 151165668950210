export default {
  translation: {
    languages: {
      title: 'Переключение языков',
      ru: 'ru',
      en: 'en',
    },
    theme: {
      title: 'Переключение цветовой темы',
      light: 'светлая',
      dark: 'тёмная',
    },
    header: {
      title: 'Приветсвую! Меня зовут Анатолий',
      links: {
        git: 'Мой GitHub',
        tg: 'Мой Telegram',
        habr: 'Мой Хабр',
        in: 'Мой LinkedIn',
        em: 'Мой Емaйл',
      },
    },
    main: {
      title: 'I`m frontend developer',
      menu: {
        stack: 'Stack',
        about: 'About',
        skills: 'Skills',
      },
      dev: 'САЙТ НАХОДИТСЯ В РАЗРАБОТКЕ',
    },
    stack: {
      title: 'Мой стэк',
    },
    aboutMe: {
      title: 'Обо мне',
      description: {
        first_1_P: 'Я начинающий фронтенд-разработчик. ',
        second_1_p: 'Моя страсть к программированию началась в 2021 году, и достаточно случайно. Благодаря рекомендациям YouTube, я прошел небольшой обучающий курс по основам "Python" и решил, что ',
        second_2_sp: 'программирование ',
        second_3_p: 'станет отличным хобби, т. к. ',
        second_4_sp: 'даёт возможность решать сложные задачи и постоянно развиваться',
        second_5_p: '. Далее, до 2023 года, я прошел еще несколько курсов по основам "Python", "Java" и "JavaScript". И после долгого анализа и обдумывания я понял, что ',
        second_6_sp: 'программирование – это не просто хобби или профессия, а целый образ жизни и то, чему я хотел бы посвятить свою жизнь. ',
        third_1_p: 'А непосредственно Frontend выбрал, потому что помимо решения сложных задач, ',
        third_2_sp: 'мне нравится работать с графикой и UX/UI',
        third_3_p: ', и отчасти, «вживую» видеть плоды своих трудов.',
        fourth_1_p: 'В марте этого года ',
        fourth_2_sp: 'закончил обучение в ',
        fourth_3_p: 'школе программирования ',
        fourth_4_sp: '"Hexlet"',
        fourth_5_p: ', но развиваться как разработчик продолжаю. ',
        fourth_6_sp: 'Так же у меня есть высшее экономическое образование. ',
        fifth_1_p: 'Мои усидчивость и старательность помогают мне полностью погружаться в решение задач и успешно с ними справляться, несмотря на возможные трудности (',
        fifth_2_sp: 'я успешно выполнил все упражнения на 100%',
        fifth_2_p: ', без подсказок). Кроме того, я всегда стремлюсь к идеальному результату и выполняю работу максимально ответственно. ',
        sixth_1_sp: 'Имею большой опыт управления собственным бизнесом ',
        sixth_1_p: ', и считаю, что это один из моих плюсов. Поскольку этот опыт дал мне много полезных софт-скиллов, которые нужны для слаженной и продуктивной работы в команде. ',
      },
    },
    skills: {
      title: 'Мои навыки и опыт',
      educExp: {
        title: 'МОЙ ОПЫТ РАЗРАБОТКИ ВКЛЮЧАЕТ ОБУЧАЮЩИЕ ПРОЕКТЫ НА "ХЕКСЛЕТ"',
        base: {
          title: 'Базовые проекты',
          desc_bg_a: '"Игры Разума" ',
          desc_bg_p: '- пакет из пяти консольных игр. ',
          desc_dc_a: '"Вычислитель отличий" ',
          desc_dc_p: '- утилита командной строки (cli), определяющая разницу между двумя структурами данных. ',
          desc_ra_a: '"RSS-агрегатор" ',
          desc_ra_p: '- сервис для агрегации RSS-каналов. ',
          ul: 'В рамках этих проектов я реализовал:',
          li_1: 'вёрстку с использованием Bootstrap и работу с DOM;',
          li_2: 'работу с JavaScript, с применением алгоритмов и работу со структурой данных. А также применение шаблона проектирования MVC;',
          li_3: 'линтинг кода с помощью Eslint, с применением стандарта Airbnb. Отладка и тестирование проектов с помощью фреймворка Jest;',
          li_4: 'работу с асинхронными http-запросами с применением Axios;',
          li_5: 'сборка с помощью Webpack и деплой проекта на Vercel.',
          usedStack: 'Используемый стек: JavaScript, Node.JS (работа с npm), Bootstrap, Eslint + Airbnb, Jest, Yup, i18next, Axios, Webpack, Vercel',
        },
        diploma: {
          title: 'Дипломный проект',
          desc_sc_a: ' Real-time приложение',
          desc_sc_p: ', аналог чата Slack (упрощенная версия).',
          ul: 'В рамках проекта я реализовал',
          li_1: 'верстку с помощью функциональных компонентов React, React-Bootstrap;',
          li_2: 'работу с контекстом и провайдерами, для передачи языка и авторизации, а также работу с хранилищем с помощью Redux Toolkit;',
          li_3: 'роутинг по страницам с помощью React-Router-Dom;',
          li_4: 'интернационализацию, для русского и английского языков, с помощью i18next;',
          li_5: 'работу с формами с использованием Formik, в том числе с формой авторизации. А также валидацию (Yup) и фильтрацию (Leo-Profanity) введенных данных;',
          li_6: 'работу с бэкендом с помощью socket.io, Axios, с применением REST API;',
          li_7: 'сборку проекта (Webpack), деплой (Render.com) и мониторинг ошибок в продакшене (Rollbar);',
          usedStack: 'Используемый стек: JavaScript, Node.JS (работа с npm), React, React-Dom, React-Router-Dom, React-Bootstrap, React-Redux, Redux Toolkit, Formik, React-Toastify, Eslint + Airbnb, Yup, i18next, Leo-Profanity, REST API, Axios, Socket.io, Webpack, Render.com, Rollbar',
        },
      },
      examples: {
        promiseExam: 'здесь будет компонент',
        firstExam_1: 'могу делать разные полезные компоненты',
        firstExam_2: 'используя стандартные методы или сторонние библиотеки',
        firstExam_3: 'Например - формы входа с валидацией ("Formik" и "YUP")',
        secondExam_1: 'Умею работать с провайдерами и контекстом',
        secondExam_2: 'создавать кастомные хуки и селекторы',
        secondExam_3: 'Например - для смены темы или локализации сайта',
        thirdExam_1: 'умею работать с состоянием',
        thirdExam_2: 'используя useState или Redux Toolkit',
        thirdExam_3: 'А так же как работать с фильтрацией введенных данных',
        otherExam: 'Это всего лишь небольшая часть, т.к. я постоянно развиваю свои навыки',
      },
    },
    notFound: {
      errorNumber: 404,
      exclamation: 'Упс!',
      notFound: 'Страница не найдена',
      goOut: 'Но вы можете перейти на главную страницу',
      button: 'Вернуться',
    },
    templates: {
      authorization: {
        logIn: {
          title: 'Вход',
          footer: {
            text: 'Нет аккаунта? ',
            link: 'Зарегистрироваться',
          },
          inputName: {
            label: 'Имя пользователя',
            placeholder: 'Имя пользователя',
          },
          inputPass: {
            label: 'Пароль',
            placeholder: 'Пароль',
          },
        },
        signUp: {
          title: 'Регистрация',
          footer: {
            text: 'Есть аккаунта? ',
            link: 'Войти',
          },
          inputName: {
            label: 'Имя пользователя',
            placeholder: 'Имя пользователя - от 3 до 20 символов',
          },
          inputPass: {
            label: 'Пароль',
            placeholder: 'Пароль - минимум 6 символов',
          },
          inputConfirmPass: {
            label: 'Подтвердите пароль',
            placeholder: 'Пароли должны совпадать',
          },
        },
        userEntered: {
          title: ', добро пожаловать',
          LogIn: 'Авторизация',
          SignUp: 'Регистрация',
          text: 'успешно завершена',
        },
      },
      buttons: {
        logIn: 'Войти',
        signUp: 'Зарегистрироваться',
        logOut: 'Выйти',
        up: 'Вверх',
        back: 'Back',
        addTodo: 'Добавить задачу',
        resetTodo: 'Удалить задачи',
        prevPage: 'Предыдущая страница',
        nextPage: 'Следующая страница',
      },
      modal: {
        success: 'Всё в порядке',
      },
    },
    errors: {
      invalidNameOrPass: 'Неверное имя пользователя или пароль',
      unknownErr: 'Неизвестная ошибка',
      networkErr: 'Ошибка соединения',
    },
    validationError: {
      unknownErr: 'Неизвестная ошибка',
      networkErr: 'Ошибка соединения',
      thisUserExists: 'Этот пользователь уже существует',
      thisUserDoesNotExists: 'Такого пользователя не существует',
      wronglengthName: 'От 3 до 20 символов',
      wronglengthPass: 'Минимум 6 символов',
      invalidPassConfirm: 'Пароли должны совпадать',
      requiredField: 'Обязательное поле',
    },
  },
};
